exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2lF42{width:100vw;min-height:100vh;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:start;justify-content:flex-start;-ms-flex-align:center;align-items:center;padding-top:5%;letter-spacing:.25em;background:#000622}._2U6rm{display:block;text-align:center;padding-top:64px;padding-top:4rem;width:100%}._2gLzd{width:30%;max-width:120px}._2Fh4o{width:80%;max-width:275px}.OyGqp{font-size:24px;font-size:1.5rem;font-weight:500;line-height:64px;line-height:4rem;letter-spacing:1.45pt;margin:0;padding:28.8px 0 0;padding:1.8rem 0 0}._1FpXF{font-size:20.8px;font-size:1.3rem;font-weight:400;opacity:.7;color:#c7c7c8;letter-spacing:.56pt;margin:0;padding:0 80px;padding:0 5rem}@media screen and (min-height:1080px){._2gLzd{width:10%;max-width:none}._2Fh4o{width:25%;max-width:none}}", ""]);

// exports
exports.locals = {
	"error_container": "_2lF42",
	"error__wrapper": "_2U6rm",
	"error__mola_title": "_2gLzd",
	"error__mola_background": "_2Fh4o",
	"error__title": "OyGqp",
	"error__description": "_1FpXF"
};