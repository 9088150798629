import React from 'react';

import withStyles from 'isomorphic-style-loader/lib/withStyles';

import Link from '@components/Link';
import LazyLoad from '@components/common/Lazyload';

import Layout from '../../Layout';
import styles from './error.css';
import {
  molaLogo,
  darkMode404,
  darkMode505,
  commonError,
} from '../../../global/imageUrl';

const Error = ({
  goto = '/',
  className = '',
  title = '',
  message = '',
  isDark = 0 /** isDark is color of the text */,
  status = 1 /** none, 400, 502 */
}) => {
  let imageUri;
  switch (status) {
  case 400:
    title = 'Page not found';
    imageUri = darkMode404;
    break;
  case 502:
    title = 'Bad gateway';
    imageUri = darkMode505;
    break;
  case 401:
    title = 'Unauthorized';
    imageUri = darkMode404;
    break;
  default:
    title = 'Oops, sorry :(';
    imageUri = commonError;
    break;
  }
  return (
    <Layout>
      <LazyLoad>
        <Link to={goto} className={`${styles.error_container} ${className}`}>
          <img alt="mola" src={molaLogo} className={styles.error__mola_title} />
          <div
            className={styles.error__wrapper}
            style={{ color: isDark ? 'black' : 'white' }}
          >
            <img
              alt={message}
              src={imageUri}
              className={styles.error__mola_background}
            />
            <h2 className={styles.error__title}>{title}</h2>
            <p className={styles.error__description}>{message}</p>
          </div>
        </Link>
      </LazyLoad>
    </Layout>
  );
};

export default withStyles(styles)(Error);
